import { Splash } from 'types/models/Splash';
import * as yup from 'yup';

export default yup.object().shape({
  creditCardBrand: yup.string().required('El tipo de tarjeta es requerido'),
  creditCardCardHolderName: yup
    .string()
    .required('El nombre es requerido')
    .max(26, 'Longitud máxima alcanzada')
    .matches(/^[aA-zZ\s]+$/, 'Solamente se permiten letras'),
  creditCardNumber: yup.string().required('El número de la tarjeta es requerido'),
  creditCardExpirationDate: yup.string().required('La fecha de expiración de la tarjeta es requerida'),
});

export const CreateForm = (splash: Splash | null): any => {
  if (!splash) return;

  let fullname = checkIfIsRequired(splash.showFullname, splash.fullnameIsRequired, "El nombre y apellido es obligatorio");
  let email = checkIfIsRequired(splash.showEmail, splash.emailIsRequired, "El correo electrónico es obligatorio");
  let phone = checkIfIsRequired(splash.showPhone, splash.phoneIsRequired, "El número de teléfono es obligatorio");
  let identificationNumber = checkIfIsRequired(splash.showIdentificationNumber, splash.identificationNumberIsRequired, `El ${splash.identificationNumberLabel} es obligatorio`);
  let ageRange = checkIfIsRequired(splash.showAgeRange, splash.ageRangeIsRequired, "El rango de edad es obligatorio");

  return yup.object({
    fullname, email, identificationNumber, ageRange, phone
  });
};

const checkIfIsRequired = (isShowed: boolean, isRequired: boolean, message: string) => {
  let validation = yup.string();
  if (isShowed && isRequired) {
    validation = validation.required(message);
  }

  return validation;
};
