import axios from "axios";
import type WidgetResponse from "types/responses/WidgetResponse";
import snakeToCamel from "utils/snakeToCamel";

export const getWidgetData = async (
  url: string
): Promise<WidgetResponse | null> => {
  try {
    const { data } = await axios.get(url, {
      headers: {
        "Content-Type": "application/xml",
      },
    });

    return Object.fromEntries(
      Object.entries(data).map(([k, v]) => [snakeToCamel(k), v])
    ) as unknown as WidgetResponse;
  } catch {
    return null;
  }
};
