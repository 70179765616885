/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

import React from "react";
import {
  createBottomTabNavigator,
  useBottomTabBarHeight,
} from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  useNavigation,
  useRoute,
  getFocusedRouteNameFromRoute,
} from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";

import {
  BottomTabParamList,
  HomeStackParamList,
} from "types/navigation/bottomTabNavigator";
import SplashScreen from "screens/SplashScreen";

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator(): JSX.Element | null {
  const redirectNavigator = useNavigation();
  const route = useRoute();
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <BottomTab.Navigator
        initialRouteName="Home"
        screenOptions={{
          tabBarActiveTintColor: "#f00",
          tabBarStyle: { display: "none" },
        }}
        tabBar={undefined}
      >
        <BottomTab.Screen
          name="Home"
          component={HomeNavigator}
          options={{
            headerShown: false,
            tabBarLabel: "Home",
            tabBarIcon: ({ color }) => (
              <Ionicons name="home" style={{ fontSize: 30, color }} />
            ),
          }}
        />
      </BottomTab.Navigator>
    </SafeAreaView>
  );
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const HomeStack = createStackNavigator<HomeStackParamList>();

const HomeNavigator = (): JSX.Element => {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen
        name="SplashScreen"
        component={SplashScreen}
        options={{ headerShown: false }}
        initialParams={undefined}
      />
    </HomeStack.Navigator>
  );
};
