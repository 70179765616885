import axios from 'axios';
import axiosInstance from './axios';
import { CheckStatusResponse } from 'types/responses/CheckStatusResponse';
import { parseVenue } from './venueService';
import User from 'types/models/User';
//import AuthData from 'types/models/AuthData';
//import User from 'types/models/User';

export const checkStatus = async (body: any): Promise<CheckStatusResponse | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: '/login/check-status',
      data: body,
    });
    return parseCheckStatusResponse(data);
  } catch (error: any) {
    return null;
  }
};

export const logConnection = async (body: { ueMac:string, venue:string }): Promise<void> => {
    axiosInstance({
      method: 'POST',
      url: '/login/log-connection',
      data: body,
    })
    .then(console.info)
    .catch(console.error)
};


export const setUserData = async (body: any): Promise<string | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `/users/set-data`,
      data: body,
    });
    return data.uri
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const reconectUser = async (userId: string, body: any): Promise<User | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'PUT',
      url: `/users/${userId}/reconect`,
      data: body
    });
    return parseUser(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseCheckStatusResponse = (data: any): CheckStatusResponse => {
  return {
    user: data.user ? parseUser(data.user) : undefined,
    venue: parseVenue(data.venue),
    actionCode: data.action_code
  };
};

const parseUser = (user: any): User => {
  return {
    id: user.id,
    firstname: user.firstname,
    macAddress: user.mac_address,
    lastname: user.lastname,
    email: user.email,
    phone: user.phone,
    profileTypeId: user.profile_type_id,
  };
};
